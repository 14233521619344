angular.module('enervexSalesappApp').controller('SystemsModalCtrl', function($scope, $uibModalInstance, _, SystemsManager) {

	if (!$scope.design.fc5layout.systems) {
		$scope.design.fc5layout.systems = []
	}
	var id = $scope.design.fc5layout.systems.length +1
	$scope.ctx = {
		newSystem: {
			id: id,
			label: "SYS"+(id)
		}
	}
	// SystemsManager.traverseSystems($scope.design)

	$scope.done = function() {
		var modalInstance = $uibModalInstance.close();
	}
	$scope.canRemoveSystems = function() {
		return SystemsManager.canRemoveSystems($scope.design)
	}
	$scope.canRemoveSystem = function(system) {
		return SystemsManager.canRemoveSystem(system, $scope.design)
	}
	$scope.canCreateSystem = function() {
		return SystemsManager.canCreateSystem($scope.design)
	}
	$scope.removeSystem = function(system) {
		if (!SystemsManager.canRemoveSystem(system, $scope.design)){
			throw new Error("Cannot remove system")
		}
		$scope.design.fc5layout.systems = _.filter($scope.design.fc5layout.systems, function(s){
			return s.id != system.id
		})
		SystemsManager.availableSystems = [{id:"All"}].concat($scope.design.fc5layout.systems)
	}
	$scope.addSystem = function(system) {
		if (!SystemsManager.canCreateSystem($scope.design)){
			throw new Error("Cannot create system for application "+$%scope.design.application.name)
		}
		if (!$scope.design.fc5layout.systems) {
			$scope.design.fc5layout.systems = []
		}
		$scope.design.fc5layout.systems.push(system)

		SystemsManager.availableSystems.push(system)
		var id = $scope.design.fc5layout.systems.length +1
		$scope.ctx.newSystem = {
			id: id,
			label: "SYS"+(id)
		}
	}
});
